import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrlService } from './api-url.service';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
})

export class ComsubExtract {
    accessToken: string;

    constructor(private dataService: DataService, private apiUrlService: ApiUrlService) {
    }

    getCommunicationTypes(): Observable<any> {
        return this.dataService.getNoParams<any>(this.apiUrlService.getcommunicationtypes);
      }

    getactiveMemberFirms(): Observable<any> {
      return this.dataService.getNoParams<any>(this.apiUrlService.activeMemberFirms);
    }

    getsubscribers(communicationTypeId: number[], memberfirmId: number): Observable<any> {
      if (memberfirmId) {
        return this.dataService.getNoParams<any>(this.apiUrlService.getsubscribers + '?communicationTypeIds=' +
        communicationTypeId + '&memberfirmid=' + memberfirmId);
      } else {
        return this.dataService.getNoParams<any>(this.apiUrlService.getsubscribers + '?communicationTypeIds=' +
        communicationTypeId + '&memberfirmid=');
      }
    }

    isUserSGAllowed(): Observable<any> {
      return this.dataService.getNoParams<any>(this.apiUrlService.isUserSGAllowed);
  }
}
