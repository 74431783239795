import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { auth$ } from '@oc/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  isBusy = false;
  loginDisplay = false;
  isCMSAdmin = false;
  appVisible = false;

  constructor(private ref: ChangeDetectorRef ) {
  }

  ngOnInit() {
    console.log(auth$.isAuthenticated);
    auth$.subscribe((val: { isAuthenticated: boolean; }) => {
      console.log('from auth$ - CommExtract');
      console.log(val);
      if (val.isAuthenticated === false) {
        console.log('should be hidden');
        this.appVisible = false;
      } else {
        console.log('showing commExtract');
        this.appVisible = true;
      }
      this.ref.detectChanges();
    });
  }


  ngOnDestroy(): void {
  }
}
