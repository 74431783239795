import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommunicationExtractionComponent } from './communication-extraction/communication-extraction.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
// import { AuthenticationGuard } from 'microsoft-adal-angular6';

const routes: Routes = [
  { path: 'communicationExtract', component: CommunicationExtractionComponent },
  { path: 'error', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule) },
  { path: '**', component: EmptyRouteComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false
      } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutes { }
