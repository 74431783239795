import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommunicationExtractionInfo } from '../shared/communicationExtractionInfo';
import { ComsubExtract } from '../shared/extract.service';

@Component({
  selector: 'app-communication-extraction',
  templateUrl: './communication-extraction.component.html',
  styleUrls: ['./communication-extraction.component.less']
})
export class CommunicationExtractionComponent implements OnInit {
  pageTitle = 'Communication Extracts';

  communicationExtractInfo = new CommunicationExtractionInfo();
  CreateExtractionForm: FormGroup;
  comTopic: FormControl = new FormControl('', Validators.compose([Validators.required]));
  memberFirm: FormControl = new FormControl('', Validators.compose([Validators.required]));
  memberFirms = [];
  ComTopics = [];
  selectedTopics = [];
  extractList = [];
  extractbutton: boolean;
  topicIds = []

  constructor(private fb: FormBuilder,
              private cosExtract: ComsubExtract,
              private toastr: ToastrService,
              private route: Router,
              private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.CreateExtractionForm = this.fb.group({
      comTopic: this.comTopic,
      // checkArray: this.fb.array([], [Validators.required]),
      memberFirm: this.memberFirm,
    });
    this.selectedTopics = [];
    this.ComTopics = [];
    this.memberFirms = [];
    this.extractList = [];
    this.extractbutton = false;
    this.topicIds = [];
    this.isUserCommunicationSubscriptionAdmin();
    this.ref.detectChanges();
  }

  isUserCommunicationSubscriptionAdmin() {
      this.cosExtract.isUserSGAllowed().subscribe(res => {
        if (res) {
          this.loadcommunicationtypes();
          this.subscribeForUpdates();
        } else {
          this.route.navigate(['error/403']);
          // this.route.navigate(['home']);
        }
      });
  }

  loadcommunicationtypes() {
    this.cosExtract.getCommunicationTypes().subscribe((response) => {
      this.ComTopics = response;
      this.loadMemberFirms();
    },
      error => {
        this.showErrorNotification('An error occurred while fetching communication topics.');
      }
    );
  }

  comTopicChange(topic, e) {

    if (e.checked) {
      this.selectedTopics.push(topic);
    } else {
      this.selectedTopics = this.selectedTopics.filter(item => item.commSubscriptionTypeId !== topic.commSubscriptionTypeId)
    }
    if(this.selectedTopics.length !== 0){
      this.topicIds = [];
      this.selectedTopics.forEach(item => {
        this.topicIds.push(item.commSubscriptionTypeId);
       })
      this.communicationExtractInfo.commTopicIds = this.topicIds;
    }else{this.topicIds = []}
   
    this.searchParamsChange();
  }

  loadMemberFirms() {
    this.cosExtract.getactiveMemberFirms().subscribe((response) => {
      this.memberFirms = response;
      this.ref.detectChanges();
    },
      error => {
        this.showErrorNotification('An error occurred while fetching active member firms.');
      }
    );
  }

  memberFirmChange() {
    this.communicationExtractInfo.memberfirmId = this.memberFirm.value;
    this.searchParamsChange();
  }


  subscribeForUpdates() {
    this.comTopic.valueChanges.subscribe(val => {
      this.searchParamsChange();
    });
    this.memberFirm.valueChanges.subscribe(val => {
      this.searchParamsChange();
    });
  }

  searchParamsChange() {
    if (this.topicIds.length > 0 || this.topicIds.length > 0 && this.memberFirm.value) {
      this.extractbutton = true;
    } else {
      this.extractbutton = false;
    }
    this.ref.detectChanges();
  }

  extract() {
    this.cosExtract.getsubscribers(this.communicationExtractInfo.commTopicIds, this.communicationExtractInfo.memberfirmId)
      .subscribe((response) => {
        this.extractList = response;
        this.ref.detectChanges();
      },
        error => {
          this.showErrorNotification('An error occurred while Communication Topic.');
        }
      );
  }

  showErrorNotification(message: string) {
    this.toastr.error(message);
  }

  showSuccessNotification(message: string) {
    this.toastr.success(message);
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
