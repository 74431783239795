<form [formGroup]="CreateExtractionForm">
<div class="contianer-fluid">
    <div class="row">
        <div class="content-root">
            <div class="content-container">
              <div class="row section-container mb-3">
                <div class="col-md-12 col-lg-12 col-xs-12">
                  <label class="form-title">{{pageTitle}}</label>
                </div>
              </div>
                <div class="row top-spacing-10">
                      <div class="form-group">
                            <label class="input-label">Communication Topics<span class="asterik">*</span></label>
                            <span class="d-block">Select communication topics to extract subscribers</span>
                            <div class="grid row mt-3">
                                <div class="col-md-6 col-lg-6 col-xs-6" *ngFor="let topic of ComTopics">
                                    <mat-checkbox (change)='comTopicChange(topic, $event)'
                                    id="{{topic.commSubscriptionTypeId}}"
                                    [value]="topic.commSubscriptionTypeId"
                                    formControlName="comTopic">
                                    <span>{{topic.title}}</span>
                                </mat-checkbox>
                                </div>
                            </div>
                            <!-- <select class="form-control" formControlName="comTopic" (change)="comTopicChange()">
                                <option value="">--Select a Communication Topic--</option>
                                <option *ngFor="let ct of ComTopics" [value]="ct.commSubscriptionTypeId">
                                    {{ct.title}}
                                </option>
                            </select> -->
                      </div>
                    <div class="col-md-6 col-lg-6 col-xs-6">
                        <div class="form-group">
                            <label class="input-label">Member Firm</label>
                            <select class="form-control" formControlName="memberFirm" (change)="memberFirmChange()">
                                <option value="">--Select a Member Firm--</option>
                                <option *ngFor="let mf of memberFirms" [value]="mf.memberFirmId">
                                    {{mf.memberFirmText}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-xs-12">
                        <div class="action-buttons-container action-buttons-root">
                            <button type="button" id="update"
                                [disabled]="!extractbutton"
                                class="btn ssp-primary-btn" (click)="extract()">
                                <i class="fa fa-floppy-o" aria-hidden="true"></i> Extract
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- end new UI -->
        
        <div class="col-sm-12">
            <div class="list-container" *ngFor="let extlist of extractList">
                <div class="list-block">
                    <button class="btn ssp-primary-btn float-right copy-btn" (click)="copyText(extlist)"> 
                        <i class="fa fa-clone" aria-hidden="true"></i> Copy All
                    </button>
                    <p >{{extlist}}</p>
                </div>
            </div>
        </div>
    </div>
     <!-- copy alll -->
</div>
</form>