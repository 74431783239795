/*
List of all web api urls and handles building of appropriate url.
*/
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  constructor() { }

  get getCurrentUser() {
    return environment.apiUrl + '/currentUserProfile';
  }

  get getmenu() {
    return environment.headerUrl + '/Menu/GetMenu?isGLobal=true';
  }

  get getprofile() {
    return environment.headerUrl + '/Menu/GetMenuPerson';
  }

  get getfooter() {
    return environment.headerUrl + '/Menu/GetFooter';
  }

  get getcommunicationtypes() {
    return environment.apiUrl + '/commsubscription/getcommunicationtypes';
  }

  get getsubscribers() {
    return environment.apiUrl + '/commsubscription/getsubscribers';
  }

  get activeMemberFirms() {
    return environment.apiUrl + '/memberFirm/activeMemberFirms';
  }

  get isUserSGAllowed() {
    return environment.apiUrl + '/commsubscription/isUserSGAllowed';
  }
}
