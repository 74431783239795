(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@oc/auth"));
	else if(typeof define === 'function' && define.amd)
		define(["@oc/auth"], factory);
	else if(typeof exports === 'object')
		exports["CommunicationExtraction"] = factory(require("@oc/auth"));
	else
		root["CommunicationExtraction"] = factory(root["@oc/auth"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__oc_auth__) {
return 