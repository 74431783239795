// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config: {
    tenant: '36da45f1-dd2c-4d1f-af13-5abe46b99921',
    popUp: false,
    clientId: 'cd8ea813-9b03-47e0-94a2-560a8e9aed8c',
    endpoints: {
      'http://localhost': 'aa028eaa-acff-4e1c-8f45-2d7ca6d18a61',
      'https://donecloudapi-dev.azurewebsites.net/api': 'aa028eaa-acff-4e1c-8f45-2d7ca6d18a61'
    },
    cacheLocation: 'localStorage',
  },
  authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921/',
  redirectUri: 'https://onecloud-commsub-extraction-dev.azurewebsites.net',
  // redirectUri: 'http://localhost:4202/',
  apiUrl: 'https://donecloudapi-dev.azurewebsites.net/api',
  // apiUrl: 'https://localhost:44355/api',
  menuSubId: '60ef483735724a9687eda37c8e32875d',
  headerUrl: 'https://lapim.mytechnology.deloitte.com/Menu/api',
  resourceUrl: 'https://lapi-mytechcommon.mytechnology.deloitte.com',
  apiResource: 'aa028eaa-acff-4e1c-8f45-2d7ca6d18a61'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
